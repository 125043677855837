var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: "footer_" + _vm.currentVersion, staticClass: "leftfooter" },
    [
      _c("div", { staticClass: "text-center mt-2" }, [
        _c("div", { staticClass: "copyright" }, [
          _vm._v(_vm._s(_vm.showVersion) + _vm._s(_vm.serverVersion)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }