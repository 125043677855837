<template>
  <div class="leftfooter" :key="'footer_' + currentVersion">
         <div class="text-center mt-2">
              <div class="copyright">{{ showVersion }}{{serverVersion}}</div>
          </div>
  </div>
</template>
<script>
export default {
  hideLocale: {
    type: Boolean,
    validator(value) {
      return value != undefined;
    },
  },
  components: {
  },
  data() {
      return {
          version: ''
      }
  },
  methods: {
      async getServerVersion() {
          if(this.$route.meta.showVersion)
          {
              if(this.user.hostname != "")
              {
                  let self = this;
                  this.$http.get(this.user.hostname + "/version")
                  .then(function(result) {
                      self.version = result.data; 
                  });
              }
          }
      }
  },
  computed: {
      current_year() {
          return new Date().getFullYear();
      },
      showVersion(){
          if(process.env.VUE_APP_GIT_COMMIT != undefined)
          {
              return " " + process.env.VUE_APP_GIT_COMMIT + " ";
          }
          return "";
      },
      serverVersion(){
          if(this.version != "")
          {
              return "v. " + this.version + " ";
          }
          return "";
      },
      currentVersion() {
          return "v" + this.commit_hash + "-" + this.package_version + "-" + this.server_version;
      },
  },
  mounted() {
      this.getServerVersion();
  }
}
</script>
